<nz-modal
  [(nzVisible)]="isVisible"
  [nzFooter]="null"
  [nzTitle]=""
  (nzOnCancel)="handleCancel()"
  nzCentered
  [nzMaskClosable]="false"
  [nzClosable]="false"
  [nzWidth]="'550px'"
>
  <ng-container *nzModalContent>
    <div class="modal-title">
      Pourriez-vous nous indiquer la raison pour laquelle vous ne souhaitez pas poursuivre
      {{
        modalType === 'coaching'
          ? 'le coaching'
          : modalType === 'supplements'
          ? 'la prise de compléments alimentaires'
          : 'le coaching et la prise de compléments alimentaires'
      }}
      ?
    </div>

    <div class="modal-wrapper">
      <!-- Supplements Questions -->
      <div *ngIf="modalType === 'supplements' || modalType === 'both'">
        <label nz-checkbox [(ngModel)]="otherSupplements" class="modal-checkbox">
          Je prends déjà d'autres compléments alimentaires
        </label>
        <br />
        <label nz-checkbox [(ngModel)]="priceTooHighSupplements" class="modal-checkbox">
          Je trouve ces compléments trop chers
        </label>
        <br />
        <label nz-checkbox [(ngModel)]="notFitted" class="modal-checkbox">
          La composition de ces compléments ne me correspond pas
        </label>
        <br />
        <label nz-checkbox [(ngModel)]="wontTakeSupplements" class="modal-checkbox">
          Je ne souhaite pas prendre de compléments alimentaires
        </label>
        <br />
        <label nz-checkbox [(ngModel)]="pregnant" class="modal-checkbox"> Je suis enceinte </label>
        <br />
        <label nz-checkbox [(ngModel)]="difficultDigest" class="modal-checkbox"> Je digère mal ces compléments </label>
        <br />
        <label nz-checkbox [(ngModel)]="tooMany" class="modal-checkbox">
          Je trouve le nombre de gélules trop important
        </label>
        <br />
        <label nz-checkbox [(ngModel)]="oftenForget" class="modal-checkbox"> Je les oublie trop souvent </label>
      </div>

      <!-- Coaching Questions -->
      <div *ngIf="modalType === 'coaching' || modalType === 'both'">
        <label nz-checkbox [(ngModel)]="notInterested" class="modal-checkbox"> Le coaching ne m'intéresse pas </label>
        <br />
        <label nz-checkbox [(ngModel)]="priceTooHigh" class="modal-checkbox"> Le prix ne me convient pas </label>
        <br />
        <label nz-checkbox [(ngModel)]="notAvailable" class="modal-checkbox">
          Je ne suis pas suffisamment disponible
        </label>
        <br />
        <label nz-checkbox [(ngModel)]="notHappy" class="modal-checkbox"> Je ne suis pas satisfaite du coaching </label>
      </div>

      <!-- Shared Question -->
      <label nz-checkbox [(ngModel)]="other" class="modal-checkbox"> Autre </label>
      <textarea
        *ngIf="other"
        #inputElement
        nz-input
        placeholder="Veuillez préciser"
        [ngModel]="value"
        (ngModelChange)="onChange($event)"
      ></textarea>
      <div *ngIf="other" class="max-warning"><small>Max 200 caractères</small></div>
    </div>

    <div class="full-center">
      <button nz-button class="bemum-purple submit-btn" nzType="default" (click)="handleOk()">Soumettre</button>
    </div>
  </ng-container>
</nz-modal>
