import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BillingInfo, PatientDto } from '@bemum/api-interfaces';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import { AuthentificationService } from '../../core/services/authentification.service';
import { PatientsService } from '../../core/services/patients.service';

enum State {
  LOADING,
  UNSUBSCRIBED,
  WILL_UNSUBSCRIBE,
  SUBSCRIBED,
}
@Component({
  selector: 'bemum-billing-information',
  templateUrl: './billing-information.component.html',
  styleUrls: ['./billing-information.component.scss'],
})
export class BillingInformationComponent implements OnInit, OnDestroy {
  // retrieve patient data
  @Input() patient: PatientDto;

  private subscriptions: Subscription[] = [];

  /** Data coming from the BeMum API */
  public billingInfo: BillingInfo;

  public currentState = State.LOADING;
  public State = State;

  constructor(
    private readonly patientsService: PatientsService,
    private readonly notificationService: NzNotificationService,
    private authentificationService: AuthentificationService
  ) {}

  ngOnInit(): void {
    // Do not display error message if patient is a shopify customer
    if (this.patient.stripeCustomerId) {
      const billingRequest = this.patientsService.getBillingInfo(this.patient?.id).subscribe({
        next: (billingInfo) => {
          this.billingInfo = billingInfo;
        },
        error: () => {
          this.notificationService.error(
            `Une erreur est survenue`,
            `Impossible de recuperer les données de facturation, veuillez réessayer un peu plus tard.`
          );
        },
      });
      this.subscriptions.push(billingRequest);
    }

    // Subscribe to current user to get update in membership when user changes subscription
    const sub = this.authentificationService.currentUser$.subscribe((patient) => {
      this.patient = patient;
      if (this.patient && this.patient.currentMembership && this.patient.futureMembership) {
        const { currentMembership, futureMembership } = this.patient;

        const noCurrentSubscription = Object.keys(currentMembership).every((key) => currentMembership[key] === false);
        const noFutureSubscription = Object.keys(futureMembership).every((key) => futureMembership[key] === false);
        if (!noCurrentSubscription && !noFutureSubscription) this.currentState = State.SUBSCRIBED;
        if (!noCurrentSubscription && noFutureSubscription) this.currentState = State.WILL_UNSUBSCRIBE;
        if (noCurrentSubscription) this.currentState = State.UNSUBSCRIBED;
      }
    });

    this.subscriptions.push(sub);
  }

  public openCustomerPortal() {
    const currentUser = this.patient?.id;
    if (!currentUser) {
      return void this.notificationService.error(`Une erreur est survenue`, `Veuillez réessayer un peu plus tard.`);
    }

    if (this.patient?.stripeCustomerId) {
      const sub = this.patientsService.createPortalSession(this.patient?.id).subscribe({
        next: (data) => {
          window.location.href = data.url;
        },
        error: (err) => {
          this.notificationService.error(`Une erreur est survenue`, `Veuillez réessayer un peu plus tard.`);
        },
      });
      this.subscriptions.push(sub);
    }
  }

  public downloadBillingStatement() {
    const currentUser = this.patient?.id;
    if (!currentUser) {
      return void this.notificationService.error(`Erreur`, `Identification impossible. Veuillez réessayer plus tard.`);
    }

    const sub = this.patientsService.getBillingStatement(currentUser).subscribe(
      (blob) => {
        const downloadURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.click();
        this.notificationService.success(`Succès`, `Le document a été téléchargé`);
      },
      () => {
        this.notificationService.error(`Erreur`, `Le téléchargement a échoué, veuillez réessayer plus tard.`);
      }
    );
    this.subscriptions.push(sub);
  }

  /** cleanup */
  ngOnDestroy() {
    this.subscriptions.forEach((subs) => subs.unsubscribe());
  }
}
