<h5 class="label">Mode de livraison</h5>

<div nz-row>
  <div>
    <span nz-icon nzType="home" nzTheme="outline" style="font-size: 20px; margin: 10px"></span>
    <button
      [ngClass]="initialPickupState ? 'btn-active' : 'btn-disable'"
      (click)="homeDelivery()"
      [disabled]="!initialPickupState"
    >
      <nz-spin *ngIf="isLoadingShip; else default"></nz-spin>
      <ng-template #default> Livraison à domicile </ng-template>
    </button>
  </div>
  <div>
    <span nz-icon nzType="shop" nzTheme="outline" style="font-size: 20px; margin: 10px"></span>
    <button
      [ngClass]="initialPickupState ? 'btn-disable' : 'btn-active'"
      (click)="showModal()"
      [disabled]="initialPickupState"
    >
      Livraison en point relais
    </button>
  </div>
</div>
<br />

<nz-modal
  [(nzVisible)]="openModal"
  (nzOnCancel)="onClose()"
  (nzAfterOpen)="initializeWidget()"
  [nzCentered]="isCentered"
  [nzFooter]="null"
  [nzStyle]="{ width: 'auto' }"
  [nzClosable]="!isLoading"
>
  <ng-container *nzModalContent>
    <div *ngIf="isLoading" class="loading-spinner">
      <nz-spin nzTip=""></nz-spin>
    </div>
    <div id="monIdDeWidgetColissimo" class=""></div>
  </ng-container>
</nz-modal>

<div *ngIf="initialPickupState" class="pickup-address-container">
  <div style="padding-bottom: 10px">{{ initialPickupAdress[0] }}</div>
  <div style="padding-bottom: 10px">{{ initialPickupAdress[1] }}</div>
  <div style="padding-bottom: 10px">{{ initialPickupAdress[2] }} {{ initialPickupAdress[3] }}</div>
  <div style="padding-bottom: 10px">{{ initialPickupAdress[4] }}</div>
</div>
<div
  *ngIf="selectPoint"
  class="pickup-info-container"
  style="padding: 20px; border: 1px solid #ccc; border-radius: 8px"
>
  <div style="padding-bottom: 10px">{{ selectPoint.nom }}</div>
  <div style="padding-bottom: 10px">{{ selectPoint.adresse1 }}</div>
  <div style="padding-bottom: 10px">{{ selectPoint.codePostal }} {{ selectPoint.localite }}</div>
  <div style="padding-bottom: 10px">{{ selectPoint.libellePays }}</div>

  <div class="button-options" style="margin-top: 20px; text-align: right">
    <div nz-row class="button-options">
      <button
        class="button-sub-options"
        type="button"
        nz-col
        nz-button
        nzType="default"
        (click)="restorePickupState()"
        style="margin-right: 17px"
      >
        Annuler
      </button>
      <button nz-col nz-button class="bemum-purple" nzType="default" (click)="updateShipping()">Enregistrer</button>
    </div>
  </div>
</div>
