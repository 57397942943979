import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

let env = 'develop';
if (environment.production) {
  env = 'production';
  enableProdMode();
}

Sentry.init({
  dsn: 'https://b06da4e7fc7242fba69c68a8ce779fc3@o333392.ingest.sentry.io/5449135',
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [new URL(environment.api_url).hostname],

      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  environment: env,
  tracesSampleRate: 1.0,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
