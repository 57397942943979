<nz-header nzJustify="center" class="header">
  <div nz-row class="nav-content">
    <div class="logo" nz-col nzXs="6" nzLg="8"></div>
    <div class="home-link" nz-col nz-col="6" nzXs="12" nzLg="8">
      <a href="https://www.bemum.co/">Bemum.co</a>
    </div>
  </div>
</nz-header>

<h2 class="title-page">Réinitialiser le mot de passe</h2>

<div nz-row class="vertical-center">
  <form nzXs="24" nz-form [formGroup]="form" (ngSubmit)="submitForm()">
    <nz-form-item *ngIf="state !== State.SUCCESS">
      <nz-form-control nzErrorTip="Veuillez entrer une adresse e-mail valide">
        <input type="email" nz-input formControlName="email" placeholder="Renseignez votre email" />
      </nz-form-control>
    </nz-form-item>

    <ng-container [ngSwitch]="state">
      <div class="container-messages">
        <div *ngSwitchCase="State.IDLE" class="detail">
          Vous recevrez dans quelques instants à cette adresse un e-mail avec un lien pour réinitialiser votre mot de
          passe.
        </div>
        <div *ngSwitchCase="State.SUCCESS" class="info-text">
          <p>Un e-mail contenant les instructions de réinitialisation vous a été envoyé.</p>
          <p class="small">
            Si vous ne recevez pas l’e-mail rapidement, vérifiez votre dossier de courriers indésirables. Nous avons
            envoyé l’e-mail depuis <span class="bold">hello@bemum.co</span>.
          </p>
        </div>
        <div *ngSwitchCase="State.ERROR" class="error-style error">Une erreur est survenue</div>
        <div *ngSwitchCase="State.USER_NOT_FOUND" class="error-style error">
          Aucun compte n’est associé à cette adresse
        </div>
        <div *ngSwitchCase="State.TOO_MANY_REQUESTS" class="warning-style">
          Une demande vient d’être réalisée pour ce compte, veuillez patienter quelques instants avant de réessayer.
        </div>
      </div>
    </ng-container>

    <button nz-button class="bemum-purple" [nzType]="'primary'" nzSize="large" *ngIf="state !== State.SUCCESS">
      Réinitialiser le mot de passe
    </button>
    <div>
      <a class="smart-link" href="/login">Revenir à la page de connexion</a>
    </div>
  </form>
</div>
