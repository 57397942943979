import { Component, OnDestroy, OnInit } from '@angular/core';
import { PatientDto } from '@bemum/api-interfaces';
import { Subscription } from 'rxjs';
import { AuthentificationService } from '../../core/services/authentification.service';

@Component({
  selector: 'bemum-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  user: PatientDto = null;

  constructor(private authentificationService: AuthentificationService) {}

  ngOnInit() {
    const sub = this.authentificationService.currentUser$.subscribe((user) => {
      this.user = user;
    });
    this.subscriptions.push(sub);
  }

  logout() {
    this.authentificationService.logout().subscribe();
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
