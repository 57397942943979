import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { fr_FR, NZ_I18N } from 'ng-zorro-antd/i18n';
import { AuthGuard } from './guards/auth.guard';
import { NoAuthGuard } from './guards/no-auth.guard';
import { HttpTokenInterceptor } from './interceptors/http.token.interceptor';
import { AuthentificationService } from './services/authentification.service';
import { CrispService } from './services/crisp.service';
import { PatientsService } from './services/patients.service';

@NgModule({
  imports: [HttpClientModule],
  exports: [HttpClientModule],
  providers: [
    AuthGuard,
    NoAuthGuard,
    AuthentificationService,
    CrispService,
    PatientsService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: NZ_I18N, useValue: fr_FR },
    { provide: APP_BASE_HREF, useValue: '/' },
  ],
})
export class CoreModule {
  // the CrispService invokation is here to instanciate the service which is called nowhere so far (it lives by itself)
  constructor(@Optional() @SkipSelf() parentModule: CoreModule, private _: CrispService) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
