import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { BillingInformationComponent } from './billing-information/billing-information.component';
import { CoachingDropOutModalComponent } from './coaching-drop-out-modal/coaching-drop-out.component';
import { MyAccountComponent } from './my-account.component';
import { PersonalInformationComponent } from './personal-information/personal-information.component';
import { ProgramDropOutModalComponent } from './program-drop-out-modal/program-drop-out.component';
import { SubscriptionBillingComponent } from './subscription-billing/subscription-billing.component';
import { SupplementsDropOutModalComponent } from './supplements-drop-out-modal/supplements-drop-out.component';
import { UnsubscribeMenuComponent } from './unsubscribe-menu/unsubscribe-menu.component';
import { ShopifyManageSubscriptionModalComponent } from './shopify-manage-sub-modal/shopify-manage-sub.component';
import { PickupPoint } from './pickup-point/pickup-point.component';

@NgModule({
  imports: [SharedModule, RouterModule.forChild([{ path: '', component: MyAccountComponent }])],
  exports: [
    MyAccountComponent,
    SubscriptionBillingComponent,
    PersonalInformationComponent,
    CoachingDropOutModalComponent,
    ProgramDropOutModalComponent,
    ShopifyManageSubscriptionModalComponent,
    SupplementsDropOutModalComponent,
    UnsubscribeMenuComponent,
    PickupPoint,
  ],
  declarations: [
    MyAccountComponent,
    SubscriptionBillingComponent,
    PersonalInformationComponent,
    BillingInformationComponent,
    CoachingDropOutModalComponent,
    ProgramDropOutModalComponent,
    ShopifyManageSubscriptionModalComponent,
    SupplementsDropOutModalComponent,
    UnsubscribeMenuComponent,
    PickupPoint,
  ],
})
export class MyAccountModule {}
