import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PatientDto } from '@bemum/api-interfaces';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import { AuthentificationService } from '../../core/services/authentification.service';
import { PatientsService } from '../../core/services/patients.service';

enum State {
  LOADING,
  UNSUBSCRIBED,
  WILL_UNSUBSCRIBE,
  SUBSCRIBED,
  DELETE_SUBSCRIPTION,
}

@Component({
  selector: 'bemum-unsubscribe-menu',
  templateUrl: './unsubscribe-menu.component.html',
  styleUrls: ['./unsubscribe-menu.component.scss'],
})
export class UnsubscribeMenuComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  @Input() public patient: PatientDto;

  public currentState = State.LOADING;
  public State = State;

  isVisibleProgramDropOutModal = false;

  constructor(
    private patientService: PatientsService,
    private notification: NzNotificationService,
    private authentificationService: AuthentificationService
  ) {}

  /**
   * On init, check if patient has active subscription
   */
  ngOnInit(): void {
    if (this.patient) {
      this.initState();
    }
  }

  /** Reset the currentState based on the patient's membership */
  initState() {
    // Subscribe to current user to get update in membership when user changes subscription
    const sub = this.authentificationService.currentUser$.subscribe((patient) => {
      this.patient = patient;
      if (this.patient && this.patient.currentMembership && this.patient.futureMembership) {
        const { currentMembership, futureMembership } = this.patient;

        const isBooleanKey = (key: string) => typeof currentMembership[key] === 'boolean';

        const noCurrentSubscription = Object.keys(currentMembership)
          .filter(isBooleanKey) // On ne prend que les propriétés de type boolean
          .every((key) => currentMembership[key] === false);

        const noFutureSubscription = Object.keys(futureMembership)
          .filter(isBooleanKey)
          .every((key) => futureMembership[key] === false);
        if (!noCurrentSubscription && !noFutureSubscription) this.currentState = State.SUBSCRIBED;
        if (!noCurrentSubscription && noFutureSubscription) this.currentState = State.WILL_UNSUBSCRIBE;
        if (noCurrentSubscription) this.currentState = State.UNSUBSCRIBED;
      }
    });
    this.subscriptions.push(sub);
  }

  /** cleanup */
  ngOnDestroy() {
    this.subscriptions.forEach((subs) => subs.unsubscribe());
  }

  setCurrentState(state: State) {
    this.currentState = state;
  }

  /**
   * After clicking on "Interrompre l'abonnement"
   * AND "Je souhaite me désabonner"
   */
  deleteSubscription(): void {
    this.currentState = State.LOADING;
    if (this.patient?.stripeCustomerId) {
      this.patientService.deleteSubscription(this.patient.id).subscribe({
        next: (res) => {
          this.patientService.get(this.patient.id).subscribe((patient) => {
            this.patient = patient;
            this.authentificationService.currentUser = this.patient;
            this.initState();
            this.notification.success(
              'Succès',
              "Votre abonnement prendra fin dès la fin du mois d'abonnement en cours"
            );
            this.isVisibleProgramDropOutModal = true;
          });
        },
        error: (err) => {
          if (err.status === 423) {
            this.notification.error(
              'Erreur',
              'Pour vous assurer un accompagnement de qualité, vous ne pouvez annuler votre abonnement dans les 3 jours précédant votre prochain prélèvement.',
              { nzDuration: 10000 }
            );
          } else {
            this.notification.error('Erreur', 'Une erreur est survenue.');
          }
          this.initState();
        },
      });
    }
  }

  onCloseModal = () => {
    this.isVisibleProgramDropOutModal = false;
  };
}
