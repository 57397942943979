import { Component, Input, ElementRef, ViewChild } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { PatientDto } from '@bemum/api-interfaces';
import { PatientsService } from '../../core/services/patients.service';

@Component({
  selector: 'bemum-program-drop-out-modal',
  templateUrl: './program-drop-out.component.html',
  styleUrls: ['./program-drop-out.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProgramDropOutModalComponent {
  @Input() public isVisible = false;
  @Input() public onClose: Function;
  @Input() public patient: PatientDto;

  @ViewChild('inputElement', { static: false }) inputElement?: ElementRef;

  pregnant = false;
  completedProgram = false;
  priceTooHigh = false;
  notHappy = false;
  stoppedTrying = false;
  anotherAdvice = false;
  temporary = false;
  other = false;

  value = '';
  maxChar = 200;

  constructor(private patientService: PatientsService) {}

  handleOk() {
    const options = {
      pregnant: this.pregnant,
      completedProgram: this.completedProgram,
      priceTooHigh: this.priceTooHigh,
      notHappy: this.notHappy,
      stoppedTrying: this.stoppedTrying,
      anotherAdvice: this.anotherAdvice,
      temporary: this.temporary,
      other: this.other,
      details: this.other ? this.value : null,
    };
    this.patientService.sendProgramDropOut(this.patient.id, options).subscribe(
      (res) => {
        this.resetForm();
      },
      (err) => {
        console.error(err);
      }
    );
    this.onClose();
  }

  handleCancel() {
    this.onClose();
  }

  onChange(input: string): void {
    this.value = input.slice(0, this.maxChar);
    this.inputElement.nativeElement.value = this.value;
  }

  resetForm() {
    this.pregnant = false;
    this.completedProgram = false;
    this.priceTooHigh = false;
    this.notHappy = false;
    this.stoppedTrying = false;
    this.anotherAdvice = false;
    this.temporary = false;
    this.other = false;
    this.value = '';
  }
}
