import { ApiProperty, IsEnum, IsNotEmpty, IsOptional, IsString, ValidateIf } from './decorators-nest';
import { PatientDto } from './patient.interface';
import { StaffDto } from './staff.interface';

/** Enum for Delivery Status */
export enum Status {
  UPCOMING = 'UPCOMING',
  TO_SEND = 'TO_SEND',
  SENT = 'SENT',
  CANCELED = 'CANCELED',
  OVERDUE = 'OVERDUE',
}

/** Event type */
export enum Event {
  PAY = 'PAY',
  FAIL_TO_PAY = 'FAIL_TO_PAY',
  SHIP = 'SHIP',
  CANCEL = 'CANCEL',
}

/** Enum for Payment Method */
export enum Shop {
  STRIPE = 'STRIPE',
  SHOPIFY = 'SHOPIFY',
}

export class DeliveryEventDto {
  @ApiProperty({
    description: 'Record identifier',
    type: 'string',
    format: 'uuid',
    example: '124ed8db-25e5-47d7-a4f3-b1d9fa934650',
  })
  public id: string;

  @ApiProperty({
    description: 'The user who triggered the event. Can be null, some events are triggered by robots (mainly webhooks)',
    nullable: true,
  })
  public staff: StaffDto;

  @ApiProperty({
    description: 'Type of event who led to a status change',
    enum: Event,
  })
  public event: Event;

  @ApiProperty({
    description: 'Date on which the event happened',
  })
  createdDate: Date;
}

export class DeliveryDto {
  @ApiProperty({
    description: 'UUID of the delivery',
    type: 'string',
    format: 'uuid',
    example: '124ed8db-25e5-47d7-a4f3-b1d9fa934650',
  })
  public id: string;

  @ApiProperty({
    description: 'Stripe or shopify Invoice Id',
    nullable: true,
  })
  public invoiceId: string;

  @ApiProperty({
    description: 'Stripe or shopify Invoice Number',
    nullable: true,
  })
  public invoiceNumber: string;

  @ApiProperty({
    description: 'Information about the delivery patient',
  })
  patient: PatientDto;

  @ApiProperty({
    description: 'Status of the Delivery',
    enum: Status,
    example: Status.UPCOMING,
  })
  public status: Status;

  @ApiProperty({
    description: 'Type of Payment Service',
    enum: Shop,
    example: Shop.STRIPE,
  })
  public shop: Shop;

  @ApiProperty({
    description: 'Colissimo tracking number',
    example: '6Q01929938642',
    nullable: true,
  })
  public trackingNumber: string;

  @ApiProperty({
    description: 'History of events for this delivery',
    isArray: true,
    type: DeliveryEventDto,
  })
  public events: DeliveryEventDto[];

  @ApiProperty({
    description: 'Creation date',
    type: 'date-time',
  })
  createdDate: Date;

  @ApiProperty({
    description: 'Last updation date',
    type: 'date-time',
  })
  updatedDate: Date;
}

/**
 * Delivery update payload
 */
export class UpdateDeliveryDto {
  @ApiProperty({
    description: 'Status of the delivery',
    enum: Status,
    example: 'UPCOMING',
    required: false,
  })
  @IsEnum(Status)
  @IsOptional()
  status: Status;

  @ApiProperty({
    description: 'Colissimo tracking number',
    example: '6Q01929938642',
    required: false,
  })
  @IsString()
  @IsOptional()
  trackingNumber: string;
}
export class CreateDeliveryEventDto {
  @ApiProperty({
    description: 'The event type',
    enum: Event,
  })
  @IsEnum(Event)
  event: Event;

  @ApiProperty({
    description: 'Tracking number. Must be set for the SHIP event, otherwise will be ignored.',
    example: '6Q01929938642',
    required: false,
  })
  @IsString()
  @IsNotEmpty()
  @ValidateIf((o: CreateDeliveryEventDto) => o.event === Event.SHIP)
  trackingNumber?: string;

  @ApiProperty({
    description: 'Must be set for the PAY event, otherwise will be ignored.',
    example: 'in_1HOi2iGc7hTiLI9H1z1YriQH',
    required: false,
  })
  @IsString()
  @IsNotEmpty()
  @ValidateIf((o: CreateDeliveryEventDto) => o.event === Event.PAY)
  invoiceId?: string;

  @ApiProperty({
    description: 'Must be set for the PAY event, otherwise will be ignored.',
    example: '654DFFAE-0003',
    required: false,
  })
  @IsString()
  @IsNotEmpty()
  @ValidateIf((o: CreateDeliveryEventDto) => o.event === Event.PAY)
  invoiceNumber?: string;
}
