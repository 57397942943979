<nz-header nzJustify="center" class="header">
  <div nz-row class="nav-content">
    <div class="logo" nz-col nzXs="6" nzLg="8"></div>
    <div class="logo-wrapper" nz-col nzXs="0" nzLg="8"></div>
    <div nz-col nzLg="8" nzXs="18" class="user-panel">
      <span class="name">{{ user?.firstname }} {{ user?.lastname }}</span>
      <button class="logout-btn" (click)="logout()" nz-button nzType="default">Déconnexion</button>
    </div>
  </div>
</nz-header>
