<nz-modal [(nzVisible)]="isVisible" [nzFooter]="null" [nzTitle]="" (nzOnCancel)="handleCancel()" nzCentered>
  <ng-container *nzModalContent>
      <div class="modal-title">
        Pourriez-vous nous indiquer la raison pour laquelle vous ne souhaitez pas poursuivre votre programme Bemum ?
      </div>
    
    <div class="modal-wrapper">
      <label nz-checkbox [(ngModel)]="pregnant" class="modal-checkbox">
        Je suis enceinte
      </label>
      <br />
      <label nz-checkbox [(ngModel)]="completedProgram" class="modal-checkbox">
        Je pense avoir fait le tour du programme
      </label>
      <br />
      <label nz-checkbox [(ngModel)]="priceTooHigh" class="modal-checkbox">
        Je trouve le programme trop cher
      </label>
      <br />
      <label nz-checkbox [(ngModel)]="notHappy" class="modal-checkbox">
        Je ne suis pas satisfaite
      </label>
      <br />
      <label nz-checkbox [(ngModel)]="stoppedTrying" class="modal-checkbox">
        J'arrête les essais bébés
      </label>
      <br />
      <label nz-checkbox [(ngModel)]="anotherAdvice" class="modal-checkbox">
        On m'a conseillé autre chose
      </label>
      <br />
      <label nz-checkbox [(ngModel)]="temporary" class="modal-checkbox">
        C'est temporaire, je vais bientôt me réabonner
      </label>
      <br />
      <label nz-checkbox [(ngModel)]="other" class="modal-checkbox">
        Autre
      </label>
      <textarea *ngIf="other" #inputElement nz-input placeholder="Veuillez préciser" [ngModel]="value" (ngModelChange)="onChange($event)"></textarea>
      <div *ngIf="other" class='max-warning'><small>Max 200 caractères</small></div>
    </div>
    
    <div class="full-center">
      <button nz-button class="bemum-purple submit-btn" nzType="default" (click)="handleOk()">Soumettre</button>
    </div>
  </ng-container>
</nz-modal>