import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthentificationService } from '../core/services/authentification.service';

@Component({
  selector: 'bemum-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  isSubmitting = false;
  isSuccess = false;
  error = false;
  token = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private authentificationService: AuthentificationService,
    private notification: NzNotificationService,
    private router: Router
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.token = params['token'];
      if (!this.token) {
        this.isSubmitting = true;
      }
    });
  }

  submitForm(): void {
    this.error = false;

    for (const i in this.validateForm.controls) {
      if (this.validateForm.controls[i]) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }

    if (this.validateForm.status === 'VALID') {
      this.isSubmitting = true;
      const { password } = this.validateForm.value;
      this.authentificationService.resetPassword(this.token, { password }).subscribe(
        () => {
          this.isSubmitting = false;
          this.error = false;
          this.isSuccess = true;
          this.notification.success(
            'Succès',
            'Votre nouveau mot de passe a été enregistré. Utilisez-le pour vous connecter à votre compte BeMum'
          );
          this.router.navigateByUrl('login');
        },
        () => {
          this.isSubmitting = false;
          this.error = true;
        }
      );
    }
  }

  updateConfirmValidator(): void {
    /** wait for refresh value */
    Promise.resolve().then(() => this.validateForm.controls.checkPassword.updateValueAndValidity());
  }

  confirmationValidator = (control: UntypedFormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.validateForm.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  getCaptcha(e: MouseEvent): void {
    e.preventDefault();
  }

  ngOnInit(): void {
    this.authentificationService.logout();
    /**
     * Validate password
     * Password must be at least 8 characters long, and contain at least 3 of the 4 categories : lowercase, uppercase, number, special chars
     */
    this.validateForm = this.fb.group({
      password: [
        null,
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(
            /(((?=.*\W+)(?=.*[A-Z])(?=.*[a-z]))|((?=.*\d)(?=.*[A-Z])(?=.*[a-z]))|((?=.*\d)(?=.*\W+)(?=.*[a-z]))|((?=.*\d)(?=.*\W+)(?=.*[A-Z]))).*$/
          ),
        ],
      ],
      checkPassword: [null, [Validators.required, this.confirmationValidator]],
    });
  }
}
