import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'bemum-not-found',
  template: `<nz-result nzStatus="404" nzTitle="404" nzSubTitle="Cette page n'existe pas, désolé">
    <div nz-result-extra>
      <a [routerLink]="'/'" nz-button nzType="primary">Retour à l'accueil</a>
    </div>
  </nz-result>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent {}
