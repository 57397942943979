import { ApiProperty, IsEmail, IsEnum, IsNumber, IsOptional, IsString, PartialType } from './decorators-nest';

enum YesNo {
  YES = 'oui',
  NO = 'non',
}

enum BabyJourney {
  PREPARE = 'je-veux-préparer-ma-grossesse',
  DIFFICULTIES = 'je-n-arrive-pas-a-tomber-enceinte',
  INFERTILITY = 'j-ai-un-diagnostic-d-infertilite',
}

enum InfertilityDiagnosis {
  SOPK = 'sopk',
  ENDOMETRIOSE = 'endometriose',
  INFERTILITY = 'infertility',
  NONE = 'aucun',
}

enum ToGetPregnant {
  WEIGHT = 'weight',
  FOOD = 'food',
  LIFE = 'life',
  FINE = 'fine',
  NONE = 'aucun',
}

enum Alcohol {
  NONE = 'aucun',
  ONE_TWO = '1-2',
  THREE_FOUR = '3-4',
  FIVE_AND_MORE = '5+',
}

enum PhysicalActivity {
  RARELY = 'rarely',
  WEEKLY = 'weekly',
  FREQUENTLY = 'frequently',
}

enum FruitsVeggiesAndOil {
  NONE = 'aucun',
  ONE = '1',
  TWO_AND_MORE = '2+',
}

enum OilyFish {
  NONE = 'aucun',
  ONE_TWO = '1-2',
  THREE_AND_MORE = '3+',
}

enum FoodSupplements {
  VitaminB9 = 'vitamin_b9',
  VitaminC = 'vitamin_c',
  Magnesium = 'magnesium',
  Iron = 'iron',
  Omega3 = 'omega-3',
  Antioxidants = 'antioxidants',
  Multivitamins = 'multivitamins',
  Other = 'other',
}

enum RelationshipToFood {
  Food1 = 'food_1',
  Food2 = 'food_2',
  Food3 = 'food_3',
  Food4 = 'food_4',
  NONE = 'aucun',
}

enum Program {
  ESS = 'ESS',
  EP = 'E-P',
  ES = 'E-S',
  EPS = 'E-PS',
  ESS_PLUS = 'ESS+',
  EP_PLUS = 'E-P+',
  ES_PLUS = 'E-S+',
  EPS_PLUS = 'E-PS+',
}

export enum FtStatus {
  FERTILITY_TEST = 'fertility_test',
  ABANDONNED_CART = 'abandonned_cart',
  CLIENT = 'client',
}

enum Category {
  COLD_PROSPECT = 'Prospecte froide',
  TO_REMEMBER_DAY = 'A rappeler dans les jours qui viennent',
  TO_REMEMBER_MONTH = 'A rappeler dans quelques mois',
  REGISTERED = 'Inscrite',
  FOOD_BALANCE_PENDING = 'Bilan alimentaire en attente',
  FOOD_BALANCE_DONE = 'Bilan alimentaire fait - A relancer',
  MESSAGING = 'NRP / Messagerie',
}

export enum FertilityTestSortFields {
  UPDATED_DATE = 'updated_date',
  FIRSTNAME = 'firstname',
  EMAIL = 'email',
}

/**
 * Fertility Test creation payload
 */
export class CreateFertilityTestDto {
  @IsEmail()
  @ApiProperty({
    description: 'Email',
    example: 'my@email.com',
  })
  email: string;

  @IsString()
  @ApiProperty({
    description: 'Firstname',
    example: 'Amélie',
  })
  firstname: string;

  @IsEnum(BabyJourney)
  @ApiProperty({
    description: 'Parcours grossesse',
    example: BabyJourney.PREPARE,
    enum: BabyJourney,
  })
  baby_journey: BabyJourney;

  @IsEnum(InfertilityDiagnosis, { each: true })
  @ApiProperty({
    description: 'Diagnostic',
    example: [InfertilityDiagnosis.SOPK, InfertilityDiagnosis.ENDOMETRIOSE],
    isArray: true,
    enum: InfertilityDiagnosis,
  })
  infertility_diagnosis: InfertilityDiagnosis[];

  @IsEnum(ToGetPregnant, { each: true })
  @ApiProperty({
    description: 'Objectifs',
    example: [ToGetPregnant.WEIGHT, ToGetPregnant.FOOD],
    isArray: true,
    enum: ToGetPregnant,
  })
  to_get_pregnant: ToGetPregnant[];

  @IsNumber()
  @ApiProperty({
    description: 'Age',
    example: 35,
    minimum: 18,
    maximum: 60,
  })
  age: number;

  @IsNumber()
  @ApiProperty({
    description: 'Poids',
    example: 60,
    minimum: 30,
    maximum: 210,
  })
  weight: number;

  @IsNumber()
  @ApiProperty({
    description: 'Taille',
    example: 160,
    minimum: 60,
    maximum: 250,
  })
  height: number;

  @IsEnum(YesNo)
  @ApiProperty({
    description: 'Environnement pollué',
    example: YesNo.YES,
    enum: YesNo,
  })
  environment: YesNo;

  @IsEnum(YesNo)
  @ApiProperty({
    description: 'Fumeuse',
    example: YesNo.YES,
    enum: YesNo,
  })
  smoker: YesNo;

  @IsEnum(YesNo)
  @IsOptional()
  @ApiProperty({
    description: 'Ancienne fumeuse',
    example: null,
    required: false,
    enum: YesNo,
  })
  former_smoker?: YesNo;

  @IsEnum(YesNo)
  @IsOptional()
  @ApiProperty({
    description: 'Partenaire fumeur',
    example: null,
    required: false,
    enum: YesNo,
  })
  smoking_partner?: YesNo;

  @IsEnum(Alcohol)
  @ApiProperty({
    description: 'Consomation alcool',
    example: Alcohol.NONE,
    required: false,
    enum: Alcohol,
  })
  alcohol: Alcohol;

  @IsEnum(YesNo)
  @ApiProperty({
    description: 'Sensible aux perturbateurs endocriniens',
    example: YesNo.NO,
    required: false,
    enum: YesNo,
  })
  endocrine_disruptor_sensitivity: YesNo;

  @IsNumber()
  @ApiProperty({
    description: 'Sommeil',
    example: 4,
    minimum: 0,
    maximum: 9,
  })
  stress: number;

  @IsNumber()
  @ApiProperty({
    description: 'Sommeil',
    example: 4,
    minimum: 0,
    maximum: 9,
  })
  sleep: number;

  @IsEnum(PhysicalActivity)
  @ApiProperty({
    description: 'Activité physique',
    example: PhysicalActivity.FREQUENTLY,
    required: false,
    enum: PhysicalActivity,
  })
  physical_activity: PhysicalActivity;

  @IsEnum(FruitsVeggiesAndOil)
  @ApiProperty({
    description: 'Consommation de fruits',
    example: FruitsVeggiesAndOil.ONE,
    required: false,
    enum: FruitsVeggiesAndOil,
  })
  fruits: FruitsVeggiesAndOil;

  @IsEnum(FruitsVeggiesAndOil)
  @ApiProperty({
    description: 'Consommation de légumes',
    example: FruitsVeggiesAndOil.ONE,
    required: false,
    enum: FruitsVeggiesAndOil,
  })
  veggies: FruitsVeggiesAndOil;

  @IsEnum(OilyFish)
  @ApiProperty({
    description: 'Consommation de légumes',
    example: OilyFish.THREE_AND_MORE,
    required: false,
    enum: OilyFish,
  })
  oily_fish: OilyFish;

  @IsEnum(FruitsVeggiesAndOil)
  @ApiProperty({
    description: 'Consommation d huile',
    example: FruitsVeggiesAndOil.NONE,
    required: false,
    enum: FruitsVeggiesAndOil,
  })
  oil: FruitsVeggiesAndOil;

  @IsEnum(YesNo)
  @ApiProperty({
    description: 'Consommation de compléments alimentaires',
    example: YesNo.NO,
    required: false,
    enum: YesNo,
  })
  food_supplements: YesNo;

  @IsEnum(FoodSupplements, { each: true })
  @ApiProperty({
    description: 'Compléments alimentaires',
    example: [FoodSupplements.Other],
    isArray: true,
    enum: FoodSupplements,
    required: false,
  })
  @IsOptional()
  food_supplements_content?: FoodSupplements[];

  @IsEnum(RelationshipToFood, { each: true })
  @ApiProperty({
    description: 'Rapport à l alimentation',
    example: [RelationshipToFood.NONE],
    isArray: true,
    enum: RelationshipToFood,
  })
  relationship_to_food: RelationshipToFood[];

  @IsString()
  @IsOptional()
  @ApiProperty({
    description: 'Téléphone',
    example: '06 27 22 33 44',
    required: false,
  })
  phone?: string;

  @IsEnum(Program)
  @IsOptional()
  @ApiProperty({
    description: 'Programme',
    example: Program.ES,
    enum: Program,
  })
  program: Program;

  @IsEnum(FtStatus)
  @IsOptional()
  @ApiProperty({
    description: 'Status',
    example: FtStatus.FERTILITY_TEST,
    enum: FtStatus,
  })
  status: FtStatus;

  @IsEnum(Category)
  @IsOptional()
  @ApiProperty({
    description: 'Status',
    example: Category.REGISTERED,
    enum: Category,
  })
  category: Category;

  @ApiProperty({
    description: 'Nombre de tests',
    example: 1,
    default: 1,
  })
  nbTests: number;

  @IsString()
  @IsOptional()
  @ApiProperty({
    description: 'first time test was taken',
    required: false,
  })
  firstDate: string;

  @IsString()
  @IsOptional()
  @ApiProperty({
    description: 'created at before db was created',
    required: false,
  })
  date?: string;
}

/**
 * Fertility Test update payload
 */
export class UpdateFertilityTestDto extends PartialType(CreateFertilityTestDto) {}

/**
 * Fertility Test interface
 */
export class FertilityTestDto {
  @ApiProperty({
    description: 'UUID of the test',
    example: '124ed8db-25e5-47d7-a4f3-b1d9fa934650',
  })
  id: string;

  @ApiProperty({
    description: 'Email',
    example: 'my@email.com',
  })
  email: string;

  @ApiProperty({
    description: 'Firstname',
    example: 'Amélie',
  })
  firstname: string;

  @ApiProperty({
    description: 'Parcours grossesse',
    example: BabyJourney.PREPARE,
    enum: BabyJourney,
  })
  baby_journey: BabyJourney;

  @ApiProperty({
    description: 'Diagnostic',
    example: [InfertilityDiagnosis.SOPK, InfertilityDiagnosis.ENDOMETRIOSE],
    isArray: true,
    enum: InfertilityDiagnosis,
  })
  infertility_diagnosis: InfertilityDiagnosis[];

  @ApiProperty({
    description: 'Objectifs',
    example: [ToGetPregnant.WEIGHT, ToGetPregnant.FOOD],
    isArray: true,
    enum: ToGetPregnant,
  })
  to_get_pregnant: ToGetPregnant[];

  @ApiProperty({
    description: 'Age',
    example: 35,
    minimum: 18,
    maximum: 60,
  })
  age: number;

  @ApiProperty({
    description: 'Poids',
    example: 60,
    minimum: 30,
    maximum: 210,
  })
  weight: number;

  @ApiProperty({
    description: 'Taille',
    example: 160,
    minimum: 60,
    maximum: 250,
  })
  height: number;

  @ApiProperty({
    description: 'Environnement pollué',
    example: YesNo.YES,
    enum: YesNo,
  })
  environment: YesNo;

  @ApiProperty({
    description: 'Fumeuse',
    example: YesNo.YES,
    enum: YesNo,
  })
  smoker: YesNo;

  @IsOptional()
  @ApiProperty({
    description: 'Ancienne fumeuse',
    example: null,
    required: false,
    enum: YesNo,
  })
  former_smoker?: YesNo;

  @IsOptional()
  @ApiProperty({
    description: 'Partenaire fumeur',
    example: null,
    required: false,
    enum: YesNo,
  })
  smoking_partner?: YesNo;

  @ApiProperty({
    description: 'Consomation alcool',
    example: Alcohol.NONE,
    required: false,
    enum: Alcohol,
  })
  alcohol: Alcohol;

  @ApiProperty({
    description: 'Sensible aux perturbateurs endocriniens',
    example: YesNo.NO,
    required: false,
    enum: YesNo,
  })
  endocrine_disruptor_sensitivity: YesNo;

  @ApiProperty({
    description: 'Sommeil',
    example: 4,
    minimum: 0,
    maximum: 9,
  })
  stress: number;

  @ApiProperty({
    description: 'Sommeil',
    example: 4,
    minimum: 0,
    maximum: 9,
  })
  sleep: number;

  @ApiProperty({
    description: 'Activité physique',
    example: PhysicalActivity.FREQUENTLY,
    required: false,
    enum: PhysicalActivity,
  })
  physical_activity: PhysicalActivity;

  @ApiProperty({
    description: 'Consommation de fruits',
    example: FruitsVeggiesAndOil.ONE,
    required: false,
    enum: FruitsVeggiesAndOil,
  })
  fruits: FruitsVeggiesAndOil;

  @ApiProperty({
    description: 'Consommation de légumes',
    example: FruitsVeggiesAndOil.ONE,
    required: false,
    enum: FruitsVeggiesAndOil,
  })
  veggies: FruitsVeggiesAndOil;

  @ApiProperty({
    description: 'Consommation de légumes',
    example: OilyFish.THREE_AND_MORE,
    required: false,
    enum: OilyFish,
  })
  oily_fish: OilyFish;

  @ApiProperty({
    description: 'Consommation d huile',
    example: FruitsVeggiesAndOil.NONE,
    required: false,
    enum: FruitsVeggiesAndOil,
  })
  oil: FruitsVeggiesAndOil;

  @ApiProperty({
    description: 'Consommation de compléments alimentaires',
    example: YesNo.NO,
    required: false,
    enum: YesNo,
  })
  food_supplements: YesNo;

  @ApiProperty({
    description: 'Compléments alimentaires',
    example: [FoodSupplements.Other],
    isArray: true,
    enum: FoodSupplements,
  })
  food_supplements_content: FoodSupplements[];

  @ApiProperty({
    description: 'Rapport à l alimentation',
    example: [RelationshipToFood.NONE],
    isArray: true,
    enum: RelationshipToFood,
  })
  relationship_to_food: RelationshipToFood[];

  @ApiProperty({
    description: 'Téléphone',
    example: '06 27 22 33 44',
  })
  phone: string;

  @IsEnum(Program)
  @ApiProperty({
    description: 'Programme',
    example: Program.ES,
    enum: Program,
  })
  program: Program;

  @IsEnum(FtStatus)
  @IsOptional()
  @ApiProperty({
    description: 'Status',
    example: FtStatus.FERTILITY_TEST,
    enum: FtStatus,
  })
  status: FtStatus;

  @IsEnum(Category)
  @IsOptional()
  @ApiProperty({
    description: 'Status',
    example: Category.REGISTERED,
    enum: Category,
  })
  category: Category;

  @ApiProperty({
    description: 'number of times the test has been taken',
  })
  nbTests: number;

  @ApiProperty({
    description: 'created at before db was created',
  })
  date: Date;

  @ApiProperty({
    description: 'created at timestamp',
  })
  createdDate: Date;

  @ApiProperty({
    description: 'updated at timestamp',
  })
  updatedDate: Date;

  @ApiProperty({
    description: 'version number',
  })
  version: number;
}
