import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { AuthentificationService } from './authentification.service';

declare const $crisp: any;

@Injectable({
  providedIn: 'root',
})
export class CrispService implements OnDestroy {
  /** Hold a reference to all rxjs subscriptions */
  private subscriptions: Subscription[] = [];
  crispWebsiteId = environment.crisp_website_id;

  constructor(private authn: AuthentificationService) {
    this.injectCrisp();
    const sub = this.authn.currentUser$.subscribe((patient) => {
      if (!patient) {
        return this.resetCrispSession();
      }
      this.setCrispEmail(patient.email);
    });
    this.subscriptions.push(sub);
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  /** Inject the Crisp snippet into the DOM  */
  injectCrisp() {
    const node = document.createElement('script');
    node.type = 'text/javascript';
    node.text = `
    window.$crisp=[];
    window.CRISP_WEBSITE_ID="${this.crispWebsiteId}";
    (function(){
      d=document;
      s=d.createElement("script");
      s.src="https://client.crisp.chat/l.js";
      s.async=1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();`;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  /** Set the email in the Crisp widget */
  setCrispEmail(email) {
    if (typeof $crisp === 'undefined') {
      return;
    }
    $crisp.push(['set', 'user:email', email]);
  }

  /** Reset the Crisp widget */
  resetCrispSession() {
    if (typeof $crisp === 'undefined') {
      return;
    }
    $crisp?.push(['do', 'session:reset']);
  }
}
