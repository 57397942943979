import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NoAuthGuard } from '../core/guards/no-auth.guard';
import { SharedModule } from '../shared/shared.module';
import { AuthComponent } from './auth.component';

const routes: Routes = [{ path: '', component: AuthComponent, canActivate: [NoAuthGuard] }];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  exports: [AuthComponent],
  declarations: [AuthComponent],
})
export class AuthModule {}
