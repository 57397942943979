<div class="card-content">
  <div *ngIf="currentState === State.LOADING" class="loading-overlay">
    <nz-spin nzSize="large"></nz-spin>
  </div>
  <h5 class="label">Souscription</h5>

  <div class="current-program">
    <ng-container [ngSwitch]="patient.currentMembership.programType">
      <p *ngSwitchCase="'sopk'">PACK SOPK en cours</p>
      <p *ngSwitchCase="'age'">PACK AGE en cours</p>
      <p *ngSwitchDefault>Mon programme en cours</p>
    </ng-container>
    <ng-container *ngIf="patient.currentMembership.membershipPeriod === 'six_months'">
      <span>{{ 'Vous avez souscrit au pack de 6 mois.' }}</span
      ><br />
      <small>Ce pack est un paiement unique et ne sera pas renouvelé.</small>
    </ng-container>
    <div *ngIf="patient.currentMembership.membershipPeriod !== 'six_months'">
      <span *ngIf="currentState !== State.UNSUBSCRIBED">{{ currentMembershipText }}</span>
      <span *ngIf="currentState === State.UNSUBSCRIBED" class="unsubscribed">{{ currentMembershipText }}</span>
    </div>

    <ng-container *ngIf="hasJustUpdatedSubscription === true">
      <div>
        <span>Votre demande de modification a bien été prise en compte 🎉</span>
      </div>
    </ng-container>

    <ng-container *ngIf="currentMembershipText !== futureMembershipText">
      <ng-container [ngSwitch]="patient.futureMembership.membershipPeriod">
        <div *ngSwitchCase="'three_months'">
          <ng-container [ngSwitch]="patient.futureMembership.programType">
            <p *ngSwitchCase="'sopk'">PACK SOPK effectif dans trois mois</p>
            <p *ngSwitchCase="'age'">PACK AGE effectif dans trois mois</p>
            <p *ngSwitchDefault>Mon programme effectif dans trois mois</p>
          </ng-container>
        </div>
        <div *ngSwitchCase="'six_months'">
          <p>Mon programme effectif dans six mois</p>
        </div>
        <div *ngSwitchDefault>
          <p>Mon programme effectif au mois prochain</p>
        </div>
      </ng-container>
      <div>
        <span>{{ futureMembershipText }}</span>
      </div>
    </ng-container>

    <ng-container [ngSwitch]="true">
      <ng-container
        *ngSwitchCase="currentState === State.EDIT_SUBSCRIPTION || currentState === State.CREATE_SUBSCRIPTION"
      >
        <p class="section">L'ESSENTIEL</p>
        <p>
          L’essentiel est la base du programme BeMum. Nous vous recommandons d’inclure les trois piliers à votre
          programme. Vous pouvez cependant exclure les consultations ou les compléments. Dans tous les cas, le régime
          pro-fertilité est inclus sans frais supplémentaire.
        </p>

        <!-- ESSENTIAL FOOD SUPPLEMENTS -->
        <div class="option-container option-essentiel">
          <div class="label-container">
            <label
              [(ngModel)]="withEssentielSupplements"
              (ngModelChange)="onUpdatedOptions($event)"
              nz-checkbox
              nzDisabled="false"
            >
              <span class="option-label">Compléments Essentiel</span>
            </label>
            <span class="option-price">31.5€</span>
          </div>
          <span class="option-detail">Boosters de fertilité</span>
        </div>

        <!-- ESSENTIAL COACHING -->
        <div class="option-container option-essentiel">
          <div class="label-container">
            <label
              [(ngModel)]="withEssentielCoaching"
              (ngModelChange)="onUpdatedOptions($event)"
              nz-checkbox
              [nzDisabled]="withCoachingOption"
              nzTooltipPlacement="topLeft"
              [nzTooltipTitle]="withCoachingOption ? essentialTooltip : null"
              nz-tooltip
            >
              <span class="option-label">1 téléconsultation par mois</span>
            </label>
            <span class="option-price">30€</span>
          </div>
          <span class="option-detail">Nutritionniste spécialisée en fertilité</span>
        </div>

        <!-- REGIME PRO FERTILITÉ -->
        <div class="option-container option-essentiel">
          <div class="label-container">
            <label [ngModel]="true" nz-checkbox nzDisabled="true">
              <span class="option-label">Régime pro-fertilité</span>
            </label>
            <span class="option-price">INCLUS</span>
          </div>
          <span class="option-detail">Menus sur-mesure</span>
        </div>

        <p class="section">LES SPÉCIFIQUES</p>
        <p>Les options vous permettent d’ajuster le programme à vos objectifs et à vos besoins.</p>

        <!-- COACHING-->
        <div class="option-container">
          <div class="label-container">
            <label
              nz-checkbox
              [(ngModel)]="withCoachingOption"
              (ngModelChange)="onUpdatedOptions($event)"
              [nzDisabled]="!withEssentielCoaching"
              nzTooltipPlacement="topLeft"
              [nzTooltipTitle]="
                withEssentielCoaching
                  ? null
                  : 'Vous voulez approfondir votre accompagnement dans BeMum&nbsp;? Nous vous proposons de commencer par ajouter le coaching à votre pack Essentiel.'
              "
              nz-tooltip
            >
              <span class="option-label">Spécificité coaching</span>
            </label>
            <span class="option-price">30€ /mois</span>
          </div>
          <span class="option-detail">Une consultation supplémentaire par mois</span>
        </div>

        <!-- PROTECTEUR -->
        <div class="option-container">
          <div class="label-container">
            <label nz-checkbox [(ngModel)]="withProtecteurOption" (ngModelChange)="onUpdatedOptions($event)">
              <span class="option-label">Spécificité +35 ans</span>
            </label>
            <span class="option-price">19.8€ /mois</span>
          </div>
          <span class="option-detail">Compléments alimentaires spécifiques</span>
        </div>

        <!-- STRUCTURANT -->
        <div class="option-container">
          <div class="label-container">
            <label nz-checkbox [(ngModel)]="withStructurantOption" (ngModelChange)="onUpdatedOptions($event)">
              <span class="option-label">Spécificité poids / SOPK</span>
            </label>
            <span class="option-price">16.5€ /mois</span>
          </div>
          <span class="option-detail">Compléments alimentaires spécifiques</span>
        </div>

        <div class="total-shipping">
          <div><span class="title">Livraison</span><br /><small>Livraison offerte dès 100 € d’achat</small></div>
          <div class="title">{{ totalShipping === 0 ? 'OFFERTE' : totalShipping + '€ /mois' }}</div>
        </div>

        <div class="total-price">
          <div>
            PRIX MENSUEL DE L’ABONNEMENT
            <ng-container *ngIf="totalOptions === 0"
              ><br /><small
                >Vous désirez annuler votre abonnement ? Cliquez sur "Annuler mon abonnement" puis "Me
                désabonner".</small
              >
            </ng-container>
          </div>
          <div>{{ totalOptions + totalShipping }}€&nbsp;/mois</div>
        </div>

        <div class="promo-code">
          <p>Vous avez un code promo ? Appliquez-le à l’étape suivante.</p>
        </div>

        <div nz-row class="program-options">
          <button class="button-sub-options" (click)="initState()" nz-button nzType="default">Annuler</button>
          <button
            [disabled]="currentState === State.LOADING || totalOptions === 0"
            (click)="doChangeSubscription()"
            nz-button
            class="bemum-purple"
            nzType="default"
          >
            Valider mon abonnement
          </button>
        </div>
      </ng-container>

      <!-- Pack  (SOPK ou Age) -->

      <ng-container
        *ngSwitchCase="currentState === State.EDIT_SUBSCRIPTION_PACK || currentState === State.CREATE_SUBSCRIPTION_PACK"
      >
        <p class="section">
          Le pack <span *ngIf="programType === 'sopk'">SOPK</span> <span *ngIf="programType === 'age'">Age</span>
        </p>
        <p>
          <span *ngIf="programType === 'sopk'">Le programme de fertilité pour les femmes atteintes de SOPK</span>
          <span *ngIf="programType === 'age'">Le programme pour les femmes de plus de 35 ans</span>
        </p>

        <div class="option-container option-essentiel">
          <div class="label-container">
            <label nz-checkbox [ngModel]="true" nzDisabled>
              <span class="option-label"
                >Pack <span *ngIf="programType === 'sopk'">SOPK</span
                ><span *ngIf="programType === 'age'">AGE</span></span
              >
            </label>
            <ng-container [ngSwitch]="patient.currentMembership.membershipPeriod">
              <div *ngIf="programType === 'age'">
                <span *ngSwitchCase="'three_months'" class="option-price">207€ / 3 mois</span>
                <span *ngSwitchCase="'six_months'" class="option-price">390€ / 6 mois</span>
              </div>

              <div *ngIf="programType === 'sopk'">
                <span *ngSwitchCase="'three_months'" class="option-price">199€ / 3 mois</span>
                <span *ngSwitchCase="'six_months'" class="option-price">375€ / 6 mois</span>
              </div>
            </ng-container>
          </div>
          <span class="option-detail">UN COACHING AVEC UNE DIÉTÉTICIENNE</span>
          <span class="option-detail">DES RECETTES PROFERTILITÉ</span>
          <span class="option-detail">DES COMPLÉMENTS ALIMENTAIRES FABRIQUÉS EN FRANCE</span>
        </div>

        <!-- Section Spécifique -->
        <p class="section">LES SPÉCIFIQUES</p>
        <p>Les options vous permettent d’ajuster le programme à vos objectifs et à vos besoins.</p>

        <!-- Spécificité Coaching -->
        <div class="option-container">
          <div class="label-container">
            <label nz-checkbox [(ngModel)]="withCoachingOption" (ngModelChange)="onUpdatedOptions($event)">
              <span class="option-label">Spécificité coaching</span>
            </label>
            <ng-container [ngSwitch]="patient.currentMembership.membershipPeriod">
              <span *ngSwitchCase="'three_months'" class="option-price">90€ /3 mois</span>
              <span *ngSwitchCase="'six_months'" class="option-price">180€ /6 mois</span>
            </ng-container>
          </div>
          <span class="option-detail">Une consultation supplémentaire par mois</span>
        </div>

        <!-- Spécificité +35 ans pour SOPK -->
        <div *ngIf="programType === 'sopk'" class="option-container">
          <div class="label-container">
            <label nz-checkbox [(ngModel)]="withProtecteurOption" (ngModelChange)="onUpdatedOptions(event)">
              <span class="option-label">Spécificité +35 ans</span>
            </label>
            <ng-container [ngSwitch]="patient.currentMembership.membershipPeriod">
              <span *ngSwitchCase="'three_months'" class="option-price">50.5€ /3 mois</span>
              <span *ngSwitchCase="'six_months'" class="option-price">95€ /6 mois</span>
            </ng-container>
          </div>
          <span class="option-detail">Compléments alimentaires spécifiques</span>
        </div>

        <!-- Spécificité Poids / SOPK pour Age -->
        <div *ngIf="programType === 'age'" class="option-container">
          <div class="label-container">
            <label nz-checkbox [(ngModel)]="withStructurantOption" (ngModelChange)="onUpdatedOptions(event)">
              <span class="option-label">Spécificité poids / SOPK</span>
            </label>
            <ng-container [ngSwitch]="patient.currentMembership.membershipPeriod">
              <span *ngSwitchCase="'three_months'" class="option-price">42€ /3 mois</span>
              <span *ngSwitchCase="'six_months'" class="option-price">80€ /6 mois</span>
            </ng-container>
          </div>
          <span class="option-detail">Compléments alimentaires spécifiques</span>
        </div>

        <!-- Prix total de l'abonnement -->
        <div class="total-price">
          <div>
            PRIX DE L’ABONNEMENT
            <ng-container *ngIf="totalOptions === 0">
              <br /><small
                >Vous désirez annuler votre abonnement ? Cliquez sur "Annuler mon abonnement" puis "Me
                désabonner".</small
              >
            </ng-container>
          </div>
          <div>
            {{ totalOptions }}€
            <ng-container [ngSwitch]="patient.currentMembership.membershipPeriod">
              <span *ngSwitchCase="'three_months'">/3 mois</span>
              <span *ngSwitchCase="'six_months'">/6 mois</span>
            </ng-container>
          </div>
        </div>

        <!-- Boutons de validation -->
        <div nz-row class="program-options">
          <button class="button-sub-options" (click)="initState()" nz-button nzType="default">Annuler</button>
          <button
            [disabled]="currentState === State.LOADING || totalOptions === 0"
            (click)="doChangeSubscription()"
            nz-button
            class="bemum-purple"
            nzType="default"
          >
            Valider mon abonnement
          </button>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="currentState === State.SUBSCRIBED">
        <div nz-row class="program-options">
          <button
            (click)="handleSubscription()"
            class="bemum-purple"
            nz-button
            nzType="default"
            nz-col
            nzXs="24"
            nzSm="16"
            nzMd="24"
            nzLg="16"
            nzSpan="16"
          >
            Gérer mon abonnement
          </button>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="currentState === State.WILL_UNSUBSCRIBE || currentState === State.UNSUBSCRIBED">
        <div nz-row class="program-options" *ngIf="!shouldDisplaySubscriptionButton()">
          <button
            (click)="handleReinscription()"
            class="bemum-purple"
            nz-button
            nzType="danger"
            nz-col
            nzXs="24"
            nzSm="11"
            nzMd="24"
            nzLg="24"
            nzXl="11"
            nzSpan="11"
          >
            Me réinscrire
          </button>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <!-- <bemum-coaching-drop-out-modal
    [isVisible]="isVisibleCoachingDropOutModal"
    [onClose]="onCloseModal"
    [patient]="patient"
  >
  </bemum-coaching-drop-out-modal> -->
  <bemum-supplements-drop-out-modal
    [isVisible]="isVisible"
    [onClose]="handleModalClose"
    [patient]="patient"
    [modalType]="modalType"
  >
  </bemum-supplements-drop-out-modal>

  <bemum-shopify-manage-sub-modal
    [isVisible]="isVisibleShopifyManageSubModal"
    [onClose]="handleModalClose"
  ></bemum-shopify-manage-sub-modal>
</div>
