import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthentificationService } from '../core/services/authentification.service';

export enum State {
  IDLE,
  LOADING,
  SUCCESS,
  USER_NOT_FOUND,
  TOO_MANY_REQUESTS,
  ERROR,
}
@Component({
  selector: 'bemum-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss'],
})
export class ForgotComponent implements OnDestroy {
  public form: UntypedFormGroup = this.fb.group({
    email: [
      null,
      [Validators.email, Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
    ],
  });

  public state = State.IDLE;
  public State = State;
  private subscriptions: Subscription[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private authentificationService: AuthentificationService,
  ) {}

  submitForm() {
    for (const key of Object.keys(this.form.controls)) {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    }
    if (this.form.valid) {
      this.state = State.LOADING;

      const credentials = this.form.value;
      const sub = this.authentificationService.forgotPassword(credentials).subscribe(
        () => {
          this.state = State.SUCCESS;
        },
        (err) => {
          if (err instanceof ErrorEvent) {
            this.state = State.ERROR;
            return;
          }

          switch ((err as HttpErrorResponse).status) {
            case 404:
              this.state = State.USER_NOT_FOUND;
              break;
            case 429:
              this.state = State.TOO_MANY_REQUESTS;
              break;
            default:
              this.state = State.ERROR;
          }
        }
      );
      this.subscriptions.push(sub);
      return sub;
    }
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
