<!-- If patient is not a stripe customer, do not display payment section, it is handled via the customer portal -->
<div *ngIf="patient.stripeCustomerId">
  <div class="card-content">
    <h5 class="label" id="payment-title">Paiement</h5>
    <div class="content-infos">
      <p>Date du prochain prélèvement</p>
      <ng-container [ngSwitch]="true">
        <ng-container *ngSwitchCase="currentState === State.UNSUBSCRIBED">
          <p class="label-subscription-detail">Vous n'avez pas d'abonnement en cours</p>
        </ng-container>
        <ng-container *ngSwitchCase="currentState === State.WILL_UNSUBSCRIBE">
          <p class="label-subscription-detail">
            Votre abonnement se termine
            <span *ngIf="patient.membershipPeriod === 'six_months'">dans 6 mois,</span>
            <span *ngIf="patient.membershipPeriod === 'three_months'">dans 3 mois,</span>
            <span *ngIf="!patient.membershipPeriod === 'monthly'">le mois prochain,</span>
            vous ne serez plus prélevée par BeMum
          </p>
        </ng-container>
        <ng-container *ngSwitchCase="currentState === State.SUBSCRIBED">
          <p class="label-subscription-detail">
            {{ billingInfo?.next_billing ? (billingInfo?.next_billing | date : 'dd/MM/yyyy') : '' }}
          </p>
        </ng-container>
      </ng-container>
    </div>

    <div class="content-infos" style="line-height: 2px; padding-bottom: 0px">
      <div class="sub-content-options">
        <div>
          <p>Moyen de paiement</p>
        </div>
        <div (click)="openCustomerPortal()">
          <p style="padding-bottom: 10px">Modifier</p>
        </div>
      </div>
      <p class="label-subscription-detail" style="text-transform: inherit">
        {{
          billingInfo?.last_digit
            ? 'Carte terminant par ' + billingInfo?.last_digit
            : "vous n'avez pas enregistré de carte"
        }}
      </p>
    </div>
    <div>
      <span class="text-infos"
        >Pour éditer vos informations de paiement, vous serez redirigée vers Stripe, un service 100% sécurisé. Nous ne
        stockons aucune information sensible sur nos serveurs.
      </span>
    </div>
  </div>
</div>

<div style="margin-top: 25px">
  <div class="card-content">
    <div>
      <h5 id="bill-title" class="label">factures</h5>
      <!-- If patient is not a stripe customer, do not display stripe invoices, there are no shopify invoices (they are received by email) -->
      <div class="sub-content" style="margin-bottom: 17px" *ngIf="patient.stripeCustomerId">
        <div class="sub-content-options">
          <div>
            <p>Factures de votre abonnement</p>
          </div>
          <div (click)="openCustomerPortal()">
            <p>Consulter</p>
          </div>
        </div>
      </div>
    </div>

    <div class="sub-content">
      <div class="sub-content-options">
        <div>
          <p>Relevé des factures acquittées</p>
        </div>
        <div (click)="downloadBillingStatement()">
          <p>Télécharger</p>
        </div>
      </div>
      <div>
        <span
          >Ce document peut être envoyé à votre mutuelle pour une demande de remboursement de l'accompagnement par votre
          nutritionniste.
        </span>
      </div>
    </div>
  </div>
</div>
