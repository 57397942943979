<nz-header nzJustify="center" class="header">
  <div nz-row class="nav-content">
    <div class="logo" nz-col nzXs="6" nzLg="8"></div>
    <div class="home-link" nz-col nz-col="6" nzXs="12" nzLg="8">
      <a href="https://www.bemum.co/">Bemum.co</a>
    </div>
  </div>
</nz-header>

<div nz-row>
  <h2 nz-col class="title-page">Mon compte</h2>
</div>
<div nz-row class="vertical-center">
  <div nz-col nzXs="24" class="auth-wrapper">
    <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
      <nz-form-item>
        <nz-form-control nzErrorTip="Merci d'entrer votre adresse email">
          <nz-input-group>
            <input type="email" nz-input formControlName="email" placeholder="adresse email" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control nzErrorTip="Merci d'entrer votre mot de passe">
          <nz-input-group>
            <input type="password" nz-input formControlName="password" placeholder="mot de passe" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <div nzXs="24">
        <div class="error" *ngIf="error">{{ errorText }}</div>
        <button nz-button class="bemum-purple" [nzType]="'primary'" nzSize="large" [disabled]="isSubmitting">
          Connexion
        </button>
        <div class="link-container">
          <a href="/forgot" class="smart-link">Mot de passe oublié ?</a>
        </div>
      </div>
    </form>
  </div>
</div>
