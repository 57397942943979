import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'bemum-shopify-manage-sub-modal',
  templateUrl: './shopify-manage-sub.component.html',
  styleUrls: ['./shopify-manage-sub.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ShopifyManageSubscriptionModalComponent {
  @Input() public isVisible = false;
  @Input() public onClose: Function;

  constructor() {}

  handleOk() {
    this.onClose();
  }

  handleCancel() {
    this.onClose();
  }
}
