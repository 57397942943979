import { Component, Input, ElementRef, ViewChild } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { PatientDto } from '@bemum/api-interfaces';
import { PatientsService } from '../../core/services/patients.service';

@Component({
  selector: 'bemum-supplements-drop-out-modal',
  templateUrl: './supplements-drop-out.component.html',
  styleUrls: ['./supplements-drop-out.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SupplementsDropOutModalComponent {
  @Input() public isVisible = false;
  @Input() public onClose: Function;
  @Input() public patient: PatientDto;
  @Input() public modalType: 'coaching' | 'supplements' | 'both';

  @ViewChild('inputElement', { static: false }) inputElement?: ElementRef;

  // Coaching-specific fields
  notInterested = false;
  priceTooHigh = false;
  notAvailable = false;
  notHappy = false;

  // Supplements-specific fields
  otherSupplements = false;
  priceTooHighSupplements = false;
  notFitted = false;
  wontTakeSupplements = false;
  pregnant = false;
  difficultDigest = false;
  tooMany = false;
  oftenForget = false;

  // Shared fields
  other = false;
  value = '';
  maxChar = 200;

  constructor(private patientService: PatientsService) {}

  handleOk() {
    const options = {
      // Supplements options
      otherSupplements: this.otherSupplements,
      priceTooHighSupplements: this.priceTooHighSupplements,
      notFitted: this.notFitted,
      wontTakeSupplements: this.wontTakeSupplements,
      pregnant: this.pregnant,
      difficultDigest: this.difficultDigest,
      tooMany: this.tooMany,
      oftenForget: this.oftenForget,

      // Coaching options
      notInterested: this.notInterested,
      priceTooHigh: this.priceTooHigh,
      notAvailable: this.notAvailable,
      notHappy: this.notHappy,

      // Shared options
      other: this.other,
      details: this.other ? this.value : null,
    };

    if (this.modalType === 'coaching') {
      this.patientService.sendCoachingDropOut(this.patient.id, options).subscribe({
        next: () => {
          this.resetForm();
        },
        error: (err) => {
          console.error(err);
        },
      });
    } else if (this.modalType === 'supplements') {
      this.patientService.sendSupplementsDropOut(this.patient.id, options).subscribe({
        next: () => {
          this.resetForm();
        },
        error: (err) => {
          console.error(err);
        },
      });
    } else if (this.modalType === 'both') {
      this.patientService.sendCoachingDropOut(this.patient.id, options).subscribe({
        next: () => {
          this.patientService.sendCoachingDropOut(this.patient.id, options).subscribe({
            next: () => {
              this.resetForm();
            },
            error: (err) => console.error(err),
          });
        },
        error: (err) => {
          console.error(err);
        },
      });
    }
    this.onClose();
  }

  handleCancel() {
    this.onClose();
  }

  onChange(input: string): void {
    this.value = input.slice(0, this.maxChar);
    this.inputElement.nativeElement.value = this.value;
  }

  resetForm() {
    // Reset coaching fields
    this.notInterested = false;
    this.priceTooHigh = false;
    this.notAvailable = false;
    this.notHappy = false;

    // Reset supplements fields
    this.otherSupplements = false;
    this.priceTooHighSupplements = false;
    this.notFitted = false;
    this.wontTakeSupplements = false;
    this.pregnant = false;
    this.difficultDigest = false;
    this.tooMany = false;
    this.oftenForget = false;

    // Reset shared fields
    this.other = false;
    this.value = '';
  }
}
