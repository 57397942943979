import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { ResetPasswordComponent } from './reset-password.component';

@NgModule({
  imports: [SharedModule, RouterModule.forChild([{ path: '', component: ResetPasswordComponent }])],
  exports: [ResetPasswordComponent],
  declarations: [ResetPasswordComponent],
})
export class ResetPasswordModule {}
