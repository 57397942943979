import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { ForgotComponent } from './forgot.component';

@NgModule({
  imports: [SharedModule, RouterModule.forChild([{ path: '', component: ForgotComponent }])],
  exports: [ForgotComponent],
  declarations: [ForgotComponent],
})
export class ForgotModule {}
