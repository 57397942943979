import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  CreateJwtRequest,
  CreateJwtResponse,
  ForgotPasswordRequest,
  PatientDto,
  ResetPasswordRequest,
} from '@bemum/api-interfaces';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { PatientsService } from './patients.service';

@Injectable({
  providedIn: 'root',
})
export class AuthentificationService {
  /** Holds and emits the logged in user */
  private currentUserSubject: BehaviorSubject<PatientDto> = null;
  /** Subscribe to this property to get the logged in user and future updates */
  public currentUser$: Observable<PatientDto> = null;
  /** The currently logged in user */
  public get currentUser(): PatientDto {
    return this.currentUserSubject.value;
  }
  /** Store the new user and stream it to subscribers */
  public set currentUser(user: PatientDto) {
    this.currentUserSubject.next(user);
    localStorage.setItem('patient', JSON.stringify(user));
  }

  private accessTokenSubject: BehaviorSubject<string> = null;
  public accessToken$: Observable<string> = null;
  public get accessToken() {
    return this.accessTokenSubject.value;
  }
  public set accessToken(token: string) {
    this.accessTokenSubject.next(token);
    localStorage.setItem('accessToken', token);
  }

  private refreshTokenSubject: BehaviorSubject<string> = null;
  public refreshToken$: Observable<string> = null;
  public get refreshToken() {
    return this.refreshTokenSubject.value;
  }
  public set refreshToken(token: string) {
    this.refreshTokenSubject.next(token);
    localStorage.setItem('refreshToken', token);
  }

  /** Loads the session data from localStorage */
  constructor(private http: HttpClient, private patientsService: PatientsService, private router: Router) {
    const patient = localStorage.getItem('patient');
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    this.currentUserSubject = new BehaviorSubject<PatientDto>(JSON.parse(patient));
    this.currentUser$ = this.currentUserSubject.asObservable();

    this.accessTokenSubject = new BehaviorSubject<string>(accessToken);
    this.accessToken$ = this.accessTokenSubject.asObservable();

    this.refreshTokenSubject = new BehaviorSubject<string>(refreshToken);
    this.refreshToken$ = this.refreshTokenSubject.asObservable();
  }

  login(credentials: CreateJwtRequest) {
    return this.http.post<CreateJwtResponse>(`${environment.api_url}/authentication/patient/jwt`, credentials).pipe(
      // Graph the JWT
      switchMap((response: CreateJwtResponse) => {
        const patientId = JSON.parse(atob(response.accessToken.split('.')[1])).sub;
        this.accessToken = response.accessToken;
        this.refreshToken = response.refreshToken;
        return this.patientsService.get(patientId);
      }),
      // Graph the Patient
      map((patient: PatientDto) => (this.currentUser = patient)),
      catchError((err) => throwError(err))
    );
  }

  refresh() {
    const token = this.refreshToken;
    if (!token) {
      throw new Error('Refresh token not found');
    }

    return this.http
      .post<CreateJwtResponse>(`${environment.api_url}/authentication/patient/refresh-jwt`, {
        refreshToken: token,
      })
      .pipe(
        switchMap((data: CreateJwtResponse) => {
          const patientId = JSON.parse(atob(data.accessToken.split('.')[1])).sub;

          this.accessTokenSubject.next(data.accessToken);
          localStorage.setItem('accessToken', data.accessToken);

          this.refreshTokenSubject.next(data.refreshToken);
          localStorage.setItem('refreshToken', data.refreshToken);

          this.patientsService.get(patientId).subscribe((patient) => {
            this.currentUserSubject.next(patient);
            localStorage.setItem('patient', JSON.stringify(patient));
          });

          return of(data);
        }),
        catchError((err) => throwError(err))
      );
  }

  /**
   * Cleanup session and redirect the user to the login page
   * @see https://api.bemum.co/docs#operation/patient-jwt-revoke
   */
  logout() {
    if (!this.currentUser) {
      return;
    }

    // Call to authentification api to revoke refreshToken
    const patientId = this.currentUser.id;
    return this.http.delete(`${environment.api_url}/authentication/patient/${patientId}/refreshToken`).pipe(
      switchMap((data) => {
        window.localStorage.clear();
        this.accessTokenSubject.next(null);
        this.refreshTokenSubject.next(null);
        this.currentUserSubject.next(null);
        this.router.navigateByUrl('/login');
        return of(data);
      })
    );
  }

  forgotPassword(credentials: ForgotPasswordRequest) {
    return this.http.post<void>(`${environment.api_url}/authentication/patient/forgot-password`, credentials);
  }

  /** @todo change route to specific one not regenerating a token ? */
  checkCurrentPassword(credentials: CreateJwtRequest): Observable<CreateJwtResponse> {
    return this.http.post(`${environment.api_url}/authentication/patient/jwt`, credentials).pipe(
      map((data: CreateJwtResponse) => {
        return data;
      })
    );
  }

  resetPassword(token: string, credentials: ResetPasswordRequest): Observable<void> {
    return this.http.patch<void>(`${environment.api_url}/authentication/patient/reset-password`, credentials, {
      headers: new HttpHeaders().set('X-Reset-Token', token),
    });
  }
}
