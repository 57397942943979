<nz-header nzJustify="center" class="header">
  <div nz-row class="nav-content">
    <div class="logo" nz-col nzXs="6" nzLg="8"></div>
    <div class="home-link" nz-col nz-col="6" nzXs="12" nzLg="8">
      <a href="https://www.bemum.co/">Accueil</a>
    </div>
  </div>
</nz-header>

<h2 class="title-page">Modifier votre mot de passe</h2>
<div nz-row class="vertical-center">
  <form nzXs="24" nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
    <nz-form-item>
      <nz-form-control
        nzErrorTip="Votre mot de passe doit avoir au moins 8 caractères et respecter au moins 3 des conditions suivantes : une minuscule, une majuscule, un chiffre, un caractère spécial"
      >
        <input
          placeholder="nouveau mot de passe"
          nz-input
          type="password"
          id="password"
          formControlName="password"
          (ngModelChange)="updateConfirmValidator()"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control [nzErrorTip]="errorTpl">
        <input
          nz-input
          placeholder="confirmation mot de passe"
          type="password"
          formControlName="checkPassword"
          id="checkPassword"
        />
        <ng-template #errorTpl let-control>
          <ng-container *ngIf="control.hasError('required')"> Merci d'entrer votre mot de passe </ng-container>
          <ng-container *ngIf="control.hasError('confirm')">
            Deux mots de passe que vous saisissez sont incohérents
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <button nz-button class="bemum-purple" [nzType]="'primary'" nzSize="large">Continuer</button>
    <div>
      <a class="smart-link" href="/login">Revenir à la page de connexion</a>
    </div>
  </form>
</div>
<!-- </div> -->
