<nz-modal
  [(nzVisible)]="isVisible"
  [nzFooter]="null"
  [nzTitle]=""
  (nzOnCancel)="handleCancel()"
  nzCentered
  nzWidth="800px"
>
  <ng-container *nzModalContent>
    <div class="modal-title"><h2>Comment modifier / annuler mon abonnement ou mon moyen de paiement ?</h2></div>

    <div class="modal-wrapper">
      <h3>Je viens de m’inscrire</h3>
      <div>
        <p>
          Quelques minutes après votre inscription, vous recevez le mail intitulé <i>« votre nouvel abonnement »</i>.
          Vous pouvez cliquer sur le bouton <i>« gérer votre abonnement »</i>
        </p>
        <p>À partir de là, vous pouvez soit :</p>
        <ul>
          <li>Annuler votre abonnement en cliquant sur le bouton <i>« annuler votre abonnement »</i></li>
          <li>Avoir accès à votre abonnement, en cliquant sur le bouton <i>« plus de détails »</i></li>
        </ul>

        <p>En cliquant sur <i>« plus de détails »</i>, vous avez le récapitulatif de votre abonnement.</p>
        <p>Depuis cette page, vous pouvez :</p>
        <ul>
          <li>Modifier votre type de forfait standard/premium</li>
          <li>Renouveler votre abonnement</li>
          <li>Suspendre votre abonnement</li>
          <li>Annuler votre abonnement</li>
          <li>
            Modifier votre moyen de paiement – en cliquant sur modifier, vous recevrez un mail, intitulé
            <i>« mettre à jour votre moyen de paiement »</i> dans lequel vous devrez cliquer sur le bouton
            <i>« confirmer les informations de paiement »</i> ; vous pourrez alors modifier votre moyen de paiement
            depuis la page qui s’ouvrira
          </li>
        </ul>
      </div>

      <h3>Je me suis inscrite il y a déjà quelques jours/semaines</h3>
      <div>
        <p>
          Si vous souhaitez modifier votre abonnement un certain temps après votre inscription, vous devrez retrouver le
          mail de confirmation reçu lors de l’inscription, qui s’intitulait <i>« votre nouvel abonnement »</i>
        </p>
        <p>Cliquez sur le bouton <i>« gérer votre abonnement »</i>.</p>
        <p>
          Il se peut que le lien ait expiré ; dans ce cas, il vous suffit de renseigner le mail utilisé lors de votre
          inscription.
        </p>
        <p>
          Vous recevrez alors un mail intitulé <i>« lien pour gérer vos abonnements »</i>, dans lequel vous devrez
          cliquer sur le bouton <i>« gérer mon abonnement »</i>.
        </p>
        <p>Vous arrivez alors sur la page permettant de modifier ou stopper votre abonnement.</p>
      </div>
    </div>

    <div class="full-center">
      <button nz-button class="bemum-purple submit-btn" nzType="default" (click)="handleOk()">OK</button>
    </div>
  </ng-container>
</nz-modal>
