import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthentificationService } from '../../core/services/authentification.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authentificationService: AuthentificationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = this.authentificationService.accessToken;
    if (token) {
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }
}
