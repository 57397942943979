import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { ContainerComponent } from './container/container.component';
import { HeaderComponent } from './header/header.component';

@NgModule({
  declarations: [ContainerComponent, HeaderComponent],
  imports: [
    SharedModule,
    RouterModule.forChild([
      {
        path: '',
        component: ContainerComponent,
        children: [
          {
            path: '',
            loadChildren: () => import('../my-account/my-account.module').then((m) => m.MyAccountModule),
          },
        ],
      },
    ]),
  ],
})
export class AuthenticatedLayoutModule {}
