import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthentificationService } from '../services/authentification.service';

@Injectable()
export class NoAuthGuard implements CanActivate {
  constructor(private router: Router, private authentificationService: AuthentificationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = this.authentificationService.accessToken;
    if (!token) {
      return true;
    }

    this.router.navigateByUrl('/');
    return false;
  }
}
