import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PatientDto } from '@bemum/api-interfaces';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthentificationService } from '../../core/services/authentification.service';
import { PatientsService } from '../../core/services/patients.service';

@Component({
  selector: 'bemum-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss'],
})
export class PersonalInformationComponent implements OnInit {
  @Input() public patient: PatientDto;
  @Output() patientChange = new EventEmitter<PatientDto>();

  // forms
  public validatePwdForm!: UntypedFormGroup;
  public validateMainForm!: UntypedFormGroup;
  public validateContactInfoForm: UntypedFormGroup;

  // bollean for editForm
  public canEditPersonalInfo = false;
  public canEditcontactInfo = false;
  public canEditPassword = false;

  // for data revert
  private contactFormData: Object;

  public contactInfo!: {
    addressLine1: string;
    city: string;
    country: string;
    phoneNumber: string;
    zipCode: string;
  };

  constructor(
    private readonly patientsService: PatientsService,
    private readonly authentificationService: AuthentificationService,
    private readonly notificationService: NzNotificationService,
    private readonly fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.validatePwdForm = this.fb.group(
      {
        currentPassword: [null, [Validators.required]],
        password: [
          null,
          [
            Validators.required,
            Validators.pattern(
              /(((?=.*\W+)(?=.*[A-Z])(?=.*[a-z]))|((?=.*\d)(?=.*[A-Z])(?=.*[a-z]))|((?=.*\d)(?=.*\W+)(?=.*[a-z]))|((?=.*\d)(?=.*\W+)(?=.*[A-Z]))).*$/
            ),
          ],
        ],
        checkPassword: [null, Validators.compose([Validators.required])],
      },
      { validators: this.checkPassw }
    );

    this.validateMainForm = this.fb.group({
      firstname: [this.patient ? this.patient?.firstname : null, [Validators.required]],
      lastname: [this.patient ? this.patient?.lastname : null, [Validators.required]],
      email: [
        this.patient ? this.patient?.email : null,
        Validators.compose([
          Validators.email,
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ]),
      ],
    });

    const franceorLuxembourgorBelgiumZipcodePattern = new RegExp(
      /^((?:0[1-9]|[13-8][0-9]|2[ab1-9]|9[0-5])(?:[0-9]{3})?|9[78][1-9](?:[0-9]{2})?|((L)[\-])?(\d{4}))|(?:(?:[1-9])(?:\d{3}))/ // eslint-disable-line no-useless-escape
    );
    const franceorLuxembourgorBelgiumPhonePattern = new RegExp(
      /^((?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$|((\+|00\s?)352)?\s?6[269]1(\s?\d{3}){2}$)|(((\+|00)32[ ]?(?:\(0\)[ ]?)?)|0){1}(4(60|[789]\d)\/?(\s?\d{2}\.?){2}(\s?\d{2})|(\d\/?\s?\d{3}|\d{2}\/?\s?\d{2})(\.?\s?\d{2}){2})$/
    );

    this.contactFormData = {
      phoneNumber: [
        this.patient ? this.patient?.phoneNumber : null,
        [Validators.required, Validators.pattern(franceorLuxembourgorBelgiumPhonePattern)],
      ],
      addressLine1: [this.patient ? this.patient?.addressLine1 : null, [Validators.required]],
      zipCode: [
        this.patient ? this.patient?.zipCode : null,
        [Validators.required, Validators.pattern(franceorLuxembourgorBelgiumZipcodePattern)],
      ],
      city: [this.patient ? this.patient?.city : null, [Validators.required]],
      country: [this.patient ? this.patient?.country : null, [Validators.required]],
    };

    this.validateContactInfoForm = this.fb.group(this.contactFormData);

    this.contactInfo = this.validateContactInfoForm.value;
  }

  private checkPassw(group: UntypedFormGroup) {
    return group.value.password === group.value.checkPassword ? null : { notValid: true };
  }

  public submitPersonnalInfo() {
    this.validateMainForm.disable();
    this.updateUser(this.validateMainForm.value);
  }

  public submitContactInfo() {
    this.validateContactInfoForm.disable();
    this.updateUser(this.validateContactInfoForm.value);
  }

  public submitPasswordInfo() {
    this.authentificationService
      .checkCurrentPassword({
        email: this.patient.email,
        password: this.validatePwdForm.value.currentPassword,
      })
      .subscribe(
        () => {
          this.updateUser({ password: this.validatePwdForm.value.checkPassword });
        },
        (err) => {
          this.notificationService.error(
            'Modification impossible',
            'Le mot de passe est erroné. Veuillez entrer votre mot de passe actuel.'
          );
          this.restoreDefaultPassw();
        }
      );
  }

  private updateUser(data: object) {
    this.patientsService.update(this.patient?.id, data).subscribe(
      (patient) => {
        this.patient = patient;
        localStorage.setItem('patient', JSON.stringify(patient));
        this.canEditcontactInfo = this.canEditPersonalInfo = this.canEditPassword = false;

        // enabling all form after updated
        this.validateContactInfoForm.enable();
        this.validateMainForm.enable();
        this.validatePwdForm.enable();

        // restoring data
        this.restoreDefaultContactInfo();
        this.restoreDefaultPersonalInfo();
        this.restoreDefaultPassw();

        this.patientChange.emit(patient);

        // success notification
        this.notificationService.success('Modification terminée', 'Les modifications ont bien été enregistrées');
      },
      () =>
        this.notificationService.error(
          'Modification impossible',
          'Impossible de modifier les informations demandées, veuillez retenter plus tard.'
        )
    );
  }

  public restoreDefaultContactInfo() {
    this.validateContactInfoForm.patchValue({
      phoneNumber: this.patient ? this.patient.phoneNumber : null,
      address: this.patient ? this.patient.addressLine1 : null,
      zipCode: this.patient ? this.patient.zipCode : null,
      city: this.patient ? this.patient.city : null,
    });
  }

  public restoreDefaultPersonalInfo() {
    this.validateMainForm.patchValue({
      firstname: this.patient ? this.patient.firstname : null,
      lastname: this.patient ? this.patient.lastname : null,
      email: this.patient ? this.patient.email : null,
    });
  }

  public restoreDefaultPassw() {
    this.validatePwdForm.reset();
  }

  public patientUpdate(updatedPatient: PatientDto) {
    this.patient = updatedPatient;
    this.patientChange.emit(updatedPatient);
  }
}
