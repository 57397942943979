import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { PatientDto } from '@bemum/api-interfaces';
import { Subscription } from 'rxjs';
import { AuthentificationService } from '../core/services/authentification.service';

@Component({
  selector: 'bemum-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent implements OnInit, OnDestroy {
  isCompleted = false;
  patient: PatientDto;
  subscriptions: Subscription[] = [];

  constructor(private authentificationService: AuthentificationService, private cdr: ChangeDetectorRef) {}

  async ngOnInit(): Promise<void> {
    const sub = this.authentificationService.currentUser$.subscribe((user) => {
      this.patient = user;
      this.cdr.detectChanges();
    });
    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  logout(): void {
    this.authentificationService.logout();
  }

  onCompleted(): void {
    this.isCompleted = true;
    this.cdr.detectChanges();
  }

  onUpdate(patient: PatientDto): void {
    this.patient = patient;
    this.cdr.detectChanges();
  }
}
