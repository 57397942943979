<div *ngIf="currentState === State.SUBSCRIBED || currentState === State.DELETE_SUBSCRIPTION" class="card-content">
  <h5 class="label">TERMINER MON PROGRAMME BEMUM</h5>

  <div class="card-detail">
    <ng-container [ngSwitch]="true">
      <ng-container *ngSwitchCase="currentState === State.DELETE_SUBSCRIPTION">
        <p class="confirm-delete-title">Vous rencontrez des difficultés avec le programme ?</p>
        <p class="confirm-delete-text">
          Nous vous invitons à nous contacter via votre messagerie pour nous communiquer vos difficultés, nous vous
          aiderons à les dépasser.
        </p>

        <div nz-row class="program-options">
          <button
            [disabled]="currentState === State.LOADING"
            (click)="initState()"
            nz-button
            nz-col
            nzXs="24"
            nzSm="11"
            nzMd="24"
            nzLg="24"
            nzXl="11"
            nzSpan="11"
            nzType="default"
            class="button-sub-options"
          >
            Annuler
          </button>
          <button
            [disabled]="currentState === State.LOADING"
            (click)="deleteSubscription()"
            nz-col
            nzXs="24"
            nzSm="11"
            nzMd="24"
            nzLg="24"
            nzXl="11"
            nzSpan="11"
            nz-button
            class="btn-cancel"
          >
            Me désabonner
          </button>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="currentState === State.SUBSCRIBED">
        <div nz-row class="program-options">
          <button
            (click)="setCurrentState(State.DELETE_SUBSCRIPTION)"
            nz-button
            nzType="danger"
            nz-col
            nzSpan="16"
            class="btn-cancel only-btn"
          >
            Annuler mon abonnement
          </button>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
<div *ngIf="currentState === State.LOADING" class="loading-overlay">
  <nz-spin nzSize="large"></nz-spin>
</div>
<bemum-program-drop-out-modal [isVisible]="isVisibleProgramDropOutModal" [onClose]="onCloseModal" [patient]="patient">
</bemum-program-drop-out-modal>
