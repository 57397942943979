import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthentificationService } from '../core/services/authentification.service';

@Component({
  selector: 'bemum-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent {
  validateForm: UntypedFormGroup = this.fb.group({
    email: [null, [Validators.email, Validators.required]],
    password: [null, [Validators.required]],
  });
  isSubmitting = false;
  isSuccess = false;
  error = false;
  errorText = '';

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private authentificationService: AuthentificationService
  ) // eslint-disable-next-line no-empty-function
  {}

  submitForm(): void {
    for (const i in this.validateForm.controls) {
      if (this.validateForm.controls[i]) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }
    if (this.validateForm.status === 'VALID') {
      this.isSubmitting = true;
      const credentials = this.validateForm.value;

      this.authentificationService.login(credentials).subscribe(
        () => {
          this.isSubmitting = false;
          this.error = false;
          this.isSuccess = true;
          this.errorText = '';
          this.router.navigateByUrl('/');
        },
        (err) => {
          this.isSubmitting = false;
          this.error = true;
          this.errorText = 'Une erreur est survenue';
          switch (err.status) {
            case 401:
              this.errorText = 'Mot de passe invalide';
              break;
            case 404:
              this.errorText = 'Compte inconnu';
              break;
            case 423:
              this.errorText =
                'Trop de tentatives de connexion, votre compte est bloqué. Veuillez suivre la procédure de mot de passe oublié.';
              break;
          }
        }
      );
    }
  }
}
