import { IsOptional } from './decorators-angular';
import { IsBoolean, IsDefined, IsEmail, IsEnum, IsString, IsUrl, MaxLength, MinLength } from './decorators-nest';
import { ApiProperty } from './decorators-nest';

enum BeMumPrograms {
  ESSENTIEL = 'essentiel',
  ESSENTIEL_PLUS = 'essentiel_plus',
  ANTIOX = 'antiox',
  ANTIOX_PLUS = 'antiox_plus',
  EQUILIBRE = 'equilibre',
  EQUILIBRE_PLUS = 'equilibre_plus',
  COMPLET = 'complet',
  ABSOLU = 'absolu',
}

export enum HubSpotAudienceSegmentationTags {
  AGE = '35_ans',
  SOPK = 'sopk',
  ENDO = 'endo',
  MEDICAL_FOLLOWUP = 'suivi_medical',
  FERTILITY_TEST = 'resultat_test',
  ABANDONNED_CART = 'panier_abandonné',
  CLIENTS = 'clientes',
}

export enum HubSpotProgress {
  FERTILITY_TEST_RESULT = 'resultat fertilite',
  CART_PAGE = 'page_panier',
  PAYMENT_PAGE = 'page_paiement',
  CLIENT = 'cliente',
}

// DTO pour Fertility Test Marketing
export class HubSpotSubscribeToFertilityTestMarketingDTO {
  @ApiProperty({
    description: 'Email of the subscriber',
    example: 'foo@foo.com',
  })
  @IsEmail()
  email: string;

  @ApiProperty({
    description: 'Firstname of the subscriber.',
    example: 'john',
    required: true,
    maxLength: 255,
    minLength: 1,
  })
  @IsString()
  @MinLength(1)
  @MaxLength(255)
  FNAME: string;

  @ApiProperty({
    description: 'URL of the obtained results',
    example: 'https://www.bemum.co/resultats/programme-essentiel/',
    required: true,
  })
  @IsUrl({ require_tld: false })
  BM_RE_LINK: string;

  @ApiProperty({
    description: 'Name of the recommended BeMum program.',
    example: 'essentiel-plus',
    enum: BeMumPrograms,
    type: 'enum',
    required: true,
  })
  @IsEnum(BeMumPrograms)
  BM_PG_NAME: BeMumPrograms;

  @ApiProperty({
    description: 'Boolean to indicate if abandoned cart had coaching option',
    example: 'true',
    required: true,
  })
  @IsBoolean()
  W_COACHING: boolean;

  @ApiProperty({
    description: 'Boolean to indicate if abandoned cart had antiox option',
    example: 'true',
    required: true,
  })
  @IsBoolean()
  W_ANTIOX: boolean;

  @ApiProperty({
    description: 'Boolean to indicate if abandoned cart had equilibre option',
    example: 'true',
    required: true,
  })
  @IsBoolean()
  W_EQUILIBR: boolean;

  @ApiProperty({
    description: 'Boolean to indicate if abandoned cart prospect had more than 35 years',
    example: 'true',
    required: true,
  })
  @IsBoolean()
  AGE_35: boolean;

  @ApiProperty({
    description: 'Boolean to indicate if abandoned cart prospect had sopk',
    example: 'true',
    required: true,
  })
  @IsBoolean()
  SOPK: boolean;

  @ApiProperty({
    description: 'Phone of the subscriber.',
    example: '0668686868',
    required: false,
    maxLength: 255,
    minLength: 1,
  })
  @IsOptional()
  PHONE: string;

  @IsDefined()
  @ApiProperty({
    description: `List of tags to segment audience ('+35 ans', 'sopk', 'endo', 'suivi médical')`,
    isArray: true,
    enum: HubSpotAudienceSegmentationTags,
  })
  tags: HubSpotAudienceSegmentationTags[];
}

// DTO pour Abandoned Cart Marketing
export class HubSpotSubscribeToAbandonedCartMarketingDTO {
  @ApiProperty({
    description: 'Email of the subscriber',
    example: 'foo@foo.com',
  })
  @IsEmail()
  email: string;

  @ApiProperty({
    description: 'Firstname of the subscriber.',
    example: 'john',
    required: true,
    maxLength: 255,
    minLength: 1,
  })
  @IsString()
  @MinLength(1)
  @MaxLength(255)
  FNAME: string;

  @ApiProperty({
    description: 'URL of the abandoned cart',
    example: 'https://www.bemum.co/panier?program=essentiel',
    required: true,
  })
  @IsUrl({ require_tld: false })
  BM_PG_LINK: string;

  @ApiProperty({
    description: 'Boolean to indicate if abandoned cart had coaching option',
    example: 'true',
    required: true,
  })
  @IsBoolean()
  W_COACHING: boolean;

  @ApiProperty({
    description: 'Boolean to indicate if abandoned cart had antiox option',
    example: 'true',
    required: true,
  })
  @IsBoolean()
  W_ANTIOX: boolean;

  @ApiProperty({
    description: 'Boolean to indicate if abandoned cart had equilibre option',
    example: 'true',
    required: true,
  })
  @IsBoolean()
  W_EQUILIBR: boolean;

  @IsDefined()
  @ApiProperty({
    description: `List of tags to segment audience ('35_ans', 'sopk', 'endo', 'suivi médical')`,
    isArray: true,
    enum: HubSpotAudienceSegmentationTags,
  })
  tags: HubSpotAudienceSegmentationTags[];

  @ApiProperty({
    description: ' of the progress ',
    example: 'page_panier',
    enum: HubSpotProgress,
    type: 'enum',
    required: true,
  })
  @IsEnum(HubSpotProgress)
  progress: HubSpotProgress;
}

export class HubSpotSubscribeToPaymentMarketingDTO {
  @ApiProperty({
    description: 'Email of the subscriber',
    example: 'foo@foo.com',
  })
  @IsEmail()
  email: string;

  @ApiProperty({
    description: ' of the progress ',
    example: 'page_panier',
    enum: HubSpotProgress,
    type: 'enum',
    required: true,
  })
  @IsEnum(HubSpotProgress)
  progress: HubSpotProgress;
}

// DTO pour Clients Marketing
export class HubSpotSubscribeToClientsMarketingDTO {
  @ApiProperty({
    description: 'Email of the subscriber',
    example: 'foo@foo.com',
  })
  @IsEmail()
  email: string;

  @ApiProperty({
    description: 'Firstname of the subscriber.',
    example: 'john',
    required: true,
    maxLength: 255,
    minLength: 1,
  })
  @IsString()
  @MinLength(1)
  @MaxLength(255)
  FNAME: string;

  @ApiProperty({
    description: 'Lastname of the subscriber.',
    example: 'smith',
    required: true,
    maxLength: 255,
    minLength: 1,
  })
  @IsString()
  @MinLength(1)
  @MaxLength(255)
  LNAME: string;

  @ApiProperty({
    description: 'Address of the subscriber.',
    example: '12, rue de la république',
    required: true,
    maxLength: 255,
    minLength: 1,
  })
  @IsString()
  @MinLength(1)
  @MaxLength(255)
  ADDRESS: string;

  @ApiProperty({
    description: 'Phone of the subscriber.',
    example: '0668686868',
    required: true,
    maxLength: 255,
    minLength: 1,
  })
  @IsString()
  @MinLength(1)
  @MaxLength(255)
  PHONE: string;
}
